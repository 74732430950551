import React from "react";
import IconTitleComponent from "./IconTitleComponent";
import mobileTopupIcon from "../../assets/svgs/mobile-topup-icon.svg";
import PATHS from "../../constants/RoutePaths";
import { Link } from "react-router-dom";

const MobileTopup = () => {
  return (
    <div className="rounded-md bg-sbg p-4 laptop:p-[1.875em] flex flex-col items-center gap-16 w-full">
      <div className="space-y-6 w-full">
        <div>
          <IconTitleComponent
            iconSrc={mobileTopupIcon}
            title={"Mobile Top-up"}
          />
        </div>
        <p className="font-light text-lg text-stxt">
          Mobile Top Up on Dashboard: Top up balance to a single or multiple
          MSISDNs in quick and easy step
        </p>
      </div>
      <Link to={PATHS.TOPUP} className="btn">
        <span className="text-[1.375em] leading-5 px-3 py-2 select-none font-medium">
          Mobile Topup
        </span>
      </Link>
    </div>
  );
};

export default MobileTopup;
