import React from "react";
import Dropdown from "../../reusable/Dropdown2";
import { ReactComponent as TotalTransactionGraphIcon } from "../../assets/svgs/total-transaction-graph-icon.svg";
import { ReactComponent as TotalTransactionCountGraphIcon } from "../../assets/svgs/total-transaction-count-graph-icon.svg";
import { ReactComponent as TotalTransactionOperatorWiseGraphIcon } from "../../assets/svgs/total-transaction-operator-wise-graph-icon.svg";
import { ReactComponent as TotalTransactionStatusWiseGraphIcon } from "../../assets/svgs/success-and-failed-transaction-rate-graph-icon.svg";
import formatAmount from "../../functions/formatAmount";
import calculatePercentage from "../../functions/calculatePercentage";
import { useStats } from "../../features/StatsSlice";
import Loader from "../../reusable/Loader";
import CoulmnChart from "./charts/CoulmnChart";
import SplineChart from "./charts/SplineChart";
import DoughnutChart from "./charts/DoughnutChart";
import { toTitleCase } from "../../functions/stringFunctions";

const Stats = () => {
  const {
    filter,
    statsFilterOptions,
    updateFilter,
    totalTransactions,
    loadingTotalTransactions,
    totalTransactionsCount,
    loadingTotalTransactionsCount,
    totalTransactionsOperatorWise,
    loadingTotalTransactionsOperatorWise,
    totalTransactionsStatusWise,
    loadingTotalTransactionsStatusWise,
  } = useStats(true);
  const handleChangeGraphFilter2 = (e) => {
    updateFilter(e.target.value);
  };
  return (
    <>
      <div className="my-10 flex items-center gap-7 ">
        <p className="font-medium text-[1.375em] leading-5">Stats</p>
        <div className="h-[1px] bg-stxt flex-grow"></div>
      </div>
      <section className="grid laptop:grid-cols-6 gap-10">
        <TotalTransactionsOperatorWise
          className="laptop:col-span-3"
          totalTransactions={totalTransactionsOperatorWise}
          loading={loadingTotalTransactionsOperatorWise}
        />
        <TotalTransactionsStatusWise
          className="laptop:col-span-3"
          totalTransactions={totalTransactionsStatusWise}
          loading={loadingTotalTransactionsStatusWise}
        />
      </section>
      <div className="my-10 flex items-center gap-7 ">
        <div className="h-[1px] bg-stxt flex-grow"></div>
        <Dropdown
          value={filter}
          onChange={handleChangeGraphFilter2}
          options={statsFilterOptions}
        />
      </div>
      <section className="grid laptop:grid-cols-6 gap-10">
        <TotalTransactions
          className="laptop:col-span-4"
          totalTransactions={totalTransactions}
          loading={loadingTotalTransactions}
        />
        <TotalTransactionsCount
          className="laptop:col-span-2"
          totalTransactionsCount={totalTransactionsCount}
          loading={loadingTotalTransactionsCount}
        />
      </section>
    </>
  );
};

export default Stats;

const Loading = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <Loader />
    </div>
  );
};

const NoData = () => {
  return (
    <div className="flex items-center justify-center h-full">
      <div>No data</div>
    </div>
  );
};

const ChartSection = ({
  title,
  Icon,
  className,
  titleNeighbour,
  loading,
  noDataCondition,
  children,
}) => {
  const titleSection = (
    <div className="flex items-center gap-6">
      <Icon className="w-14" />
      <p className="font-light text-3xl">{title}</p>
    </div>
  );
  return (
    <div className={`chart-section ${className}`}>
      <div className="space-y-14 flex h-full flex-col">
        {titleNeighbour ? (
          <div className="flex justify-between items-center">
            {titleSection}
            {titleNeighbour}
          </div>
        ) : (
          titleSection
        )}
        <div className="flex-grow">
          {loading ? <Loading /> : noDataCondition ? <NoData /> : children}
        </div>
      </div>
    </div>
  );
};

const TotalTransactions = ({ className = "", totalTransactions, loading }) => {
  const { totalSent, totalReceived, dataSet } = totalTransactions;
  const receivedColor = "#BE5EFE";
  const sentColor = "#236BFE";

  return (
    <ChartSection
      title="Total Transactions"
      Icon={TotalTransactionGraphIcon}
      className={className}
      loading={loading}
      noDataCondition={
        !dataSet || !Array.isArray(dataSet) || dataSet.length === 0
      }
      titleNeighbour={
        loading ? null : (
          <div className="flex items-center justify-end gap-12 flex-wrap">
            {[
              {
                label: "Total Received",
                value: totalReceived,
                color: receivedColor,
              },
              { label: "Total Sent", value: totalSent, color: sentColor },
            ].map((item, index) => (
              <div key={index} className="flex items-center gap-2">
                <div
                  className="size-4"
                  style={{
                    backgroundColor: item.color,
                  }}
                ></div>
                <div>
                  <p className="font-light text-xs">{item.label}</p>
                  <p className="font-bold">{formatAmount(item.value)}</p>
                </div>
              </div>
            ))}
          </div>
        )
      }
    >
      <CoulmnChart
        data={dataSet}
        sentColor={sentColor}
        receivedColor={receivedColor}
      />
    </ChartSection>
  );
};

const TotalTransactionsCount = ({
  className = "",
  totalTransactionsCount = [],
  loading,
}) => {
  return (
    <ChartSection
      title="Total Transactions Count"
      Icon={TotalTransactionCountGraphIcon}
      className={className}
      loading={loading}
      noDataCondition={
        !totalTransactionsCount || totalTransactionsCount.length === 0
      }
    >
      <SplineChart data={totalTransactionsCount} />
    </ChartSection>
  );
};

const TotalTransactionsOperatorWise = ({
  className = "",
  totalTransactions,
  loading,
}) => {
  const { totalSent, dataSet } = totalTransactions;

  const operatorColors = {
    ufone: "#FFA53E",
    jazz: "#FF4747",
    telenor: "#51A4FF",
    zong: "#AFCE5E",
  };

  const formattedDataSet = dataSet.map((item) => ({
    ...item,
    key: toTitleCase(item?.operator),
    value: item?.totalSpent,
    labelKey: toTitleCase(item?.operator),
    labelValue: formatAmount(item?.totalSpent, true),
    color: operatorColors[item?.operator],
  }));

  return (
    <ChartSection
      title="Total Transaction (Operator wise)"
      Icon={TotalTransactionOperatorWiseGraphIcon}
      className={className}
      loading={loading}
      noDataCondition={false}
      // !dataSet || !Array.isArray(dataSet) || dataSet.length === 0
    >
      <DoughnutChart
        titleText="Total"
        titleValue={totalSent}
        data={formattedDataSet}
      />
    </ChartSection>
  );
};

const TotalTransactionsStatusWise = ({
  className = "",
  totalTransactions,
  loading,
}) => {
  const {
    totalTransactionsCount,
    successfulTransactionsCount,
    failedTransactionsCount,
  } = totalTransactions;

  const formattedDataSet = [
    {
      key: "Successful",
      value: successfulTransactionsCount,
      color: "#19A6AB",
      labelKey: "Successful Rate",
      labelValue: `${calculatePercentage(
        successfulTransactionsCount,
        totalTransactionsCount
      )} %`,
    },
    {
      key: "Unsuccessful",
      value: failedTransactionsCount,
      color: "#F69294",
      labelKey: "Unsuccessful Rate",
      labelValue: `${calculatePercentage(
        failedTransactionsCount,
        totalTransactionsCount
      )} %`,
    },
  ];

  return (
    <ChartSection
      title="Succeeded & Failed Transaction Rate"
      Icon={TotalTransactionStatusWiseGraphIcon}
      className={className}
      loading={loading}
      noDataCondition={!totalTransactionsCount || totalTransactionsCount === 0}
    >
      <DoughnutChart
        titleText="Counts"
        titleValue={totalTransactionsCount}
        data={formattedDataSet}
      />
    </ChartSection>
  );
};
