import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
import { convertCamelCaseToTitleCase } from "../../../functions/stringFunctions";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const CoulmnChart = ({ data = [], sentColor, receivedColor }) => {
  function convertData(data) {
    const weekday = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    // Function to generate data points for a given key
    function generateDataPoints(key) {
      if (!data || !Array.isArray(data) || data.length === 0) {
        return [];
      }
      if (data.length <= 7) {
        return data.map((entry) => ({
          y: entry[key],
          label: weekday[new Date(entry.date).getDay()].slice(0, 3),
        }));
      }

      if (data.length <= 30) {
        return data.map((entry) => ({
          y: entry[key],
          label: formatDate(entry.date),
        }));
      }

      const weekData = groupByWeekWithTotals(data);
      return weekData.map((week) => ({
        y: week[key],
        label: `Week ${week.weekNumber}`,
      }));
    }

    // Get the keys from the first entry, excluding "date"
    const keys = Object.keys(data[0]).filter((key) => key !== "date");

    // Generate the result dynamically based on the keys
    const result = keys.map((key) => ({
      type: "column",
      legendText: convertCamelCaseToTitleCase(key),
      showInLegend: false,
      color: key === "totalSent" ? sentColor : receivedColor,
      dataPoints: generateDataPoints(key),
    }));

    return result;
  }
  const options = {
    backgroundColor: "transparent",
    animationEnabled: true,
    dataPointMaxWidth: 35,
    axisX: {
      gridThickness: 0, // Hides the grid on x-axis
      lineThickness: 0.5, // Hides the Y-axis line
      lineColor: "#3D5079",
      labelFontColor: "#3D5079",
      interval: data.length <= 7 ? 1 : data.length <= 30 ? 3 : 1,
    },
    axisY: {
      gridThickness: 0.5, // Hides the grid on y-axis
      lineThickness: 0, // Hides the Y-axis line
      tickLength: 0, // Hides the ticks on Y-axis
      labelFontColor: "#3D5079",
      gridColor: "#3D5079",
    },
    data: convertData(data),
  };
  return <CanvasJSChart options={options} />;
};

export default CoulmnChart;

function formatDate(dateString) {
  const date = new Date(dateString); // Create a Date object from the input string
  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad to 2 digits
  // const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (note: months are 0-based)

  // return `${day}/${month}`; // Return the formatted date
  return `${day}`;
}

const getWeekNumber = (date) => {
  const startDate = new Date(date.getFullYear(), 0, 1); // Start of the year
  const diff = date - startDate;
  const oneDay = 1000 * 60 * 60 * 24;
  const dayOfYear = Math.floor(diff / oneDay);
  const weekNumber = Math.ceil((dayOfYear + 1) / 7); // Week starts from 1
  return weekNumber;
};

const groupByWeekWithTotals = (data) => {
  let weekData = [];
  let currentWeek = [];
  let currentDate = new Date(data[0].date);
  let startOfWeek = new Date(currentDate.setDate(currentDate.getDate() - currentDate.getDay())); // Start of the week (Sunday)

  // Iterate through each item in the dataset
  data.forEach((item) => {
    const date = new Date(item.date);
    const diffInDays = Math.floor((date - startOfWeek) / (1000 * 60 * 60 * 24));

    // If the current date is in the same week
    if (diffInDays < 7) {
      currentWeek.push(item);
    } else {
      // Save the previous week data with totals and week number
      weekData.push({
        weekNumber: getWeekNumber(startOfWeek),
        weekStartDate: startOfWeek.toISOString().split('T')[0],
        weekEndDate: new Date(startOfWeek.setDate(startOfWeek.getDate() + 6)).toISOString().split('T')[0],
        totalReceived: currentWeek.reduce((acc, curr) => acc + curr.totalReceived, 0),
        totalSent: currentWeek.reduce((acc, curr) => acc + curr.totalSent, 0),
        dataSet: currentWeek,
      });

      // Start a new week
      currentWeek = [item];
      startOfWeek = new Date(date.setDate(date.getDate() - date.getDay())); // Reset to the start of the new week
    }
  });

  // Add the last week data if present
  if (currentWeek.length > 0) {
    weekData.push({
      weekNumber: getWeekNumber(startOfWeek),
      weekStartDate: startOfWeek.toISOString().split('T')[0],
      weekEndDate: new Date(startOfWeek.setDate(startOfWeek.getDate() + 6)).toISOString().split('T')[0],
      totalReceived: currentWeek.reduce((acc, curr) => acc + curr.totalReceived, 0),
      totalSent: currentWeek.reduce((acc, curr) => acc + curr.totalSent, 0),
      dataSet: currentWeek,
    });
  }

  return weekData;
};
