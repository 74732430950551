import formatAmount from "../../functions/formatAmount";
import useGetAccountDetails from "../../hooks/useGetAccountDetails";
import totalSalesIcon from "../../assets/svgs/total-sales-icon.svg";
import averageTransactionIcon from "../../assets/svgs/average-transaction-icon.svg";
import transactionCountIcon from "../../assets/svgs/transaction-count-icon.svg";

const SalesOverview = () => {
  const { todaySalesStats, loading } = useGetAccountDetails();
  return (
    <>
      <div className="my-10 flex items-center gap-7 ">
        <p className="font-medium text-[1.375em] leading-5">
          {/* Today  */}
          Sales Overview
        </p>
        <div className="h-[1px] bg-stxt flex-grow"></div>
      </div>
      {loading ? (
        <div className="p-10 text-3xl bg-sbg rounded-md flex justify-center items-center">
          Loading...
        </div>
      ) : (
        <div className="flex justify-between items-center flex-wrap gap-8">
          {[
            {
              icon: totalSalesIcon,
              title: "Total Sales",
              value: formatAmount(todaySalesStats?.totalSales),
            },
            {
              icon: averageTransactionIcon,
              title: "Average Transaction",
              value: formatAmount(todaySalesStats?.averageTransaction || 0),
              // value: formatAmount(todaySalesStats?.averageTransaction),
            },
            {
              icon: transactionCountIcon,
              title: "Successful Transactions",
              value: todaySalesStats?.successfulTransactions,
            },
          ].map((item, index) => (
            <div
              key={index}
              className="p-10 text-xs tablet:text-base bg-sbg rounded-md flex gap-10 items-center mb-5 flex-nowrap text-nowrap min-w-[24em] flex-grow"
            >
              <div>
                <img
                  src={item.icon}
                  className="h-[3.375em] w-[3.375em] min-h-[3.375em] min-w-[3.375em] max-h-[3.375em] max-w-[3.375em]"
                  alt=""
                />
              </div>
              <div className="space-y-2.5">
                <p className="font-light tablet:text-xl">{item.title}</p>
                <p className="font-bold text-xl tablet:text-2xl">
                  {item.value}
                </p>
              </div>
            </div>
          ))}
        </div>
      )}
    </>
  );
};

export default SalesOverview;
