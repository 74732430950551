import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const DoughnutChart = ({ titleText = "", titleValue = "", data = [] }) => {
  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    data: [
      {
        type: "doughnut",
        showInLegend: false,
        dataPoints: data.map((item) => ({
          name: item?.key,
          y: item?.value,
          color: item?.color,
        })),
      },
    ],
  };
  const showGraph = titleValue > 0;
  return (
    <div
      className={`w-full grid ${
        showGraph ? "grid-cols-2" : "h-full place-items-center"
      }`}
    >
      {showGraph && (
        <div className="relative">
          <div className="absolute w-full h-full grid place-items-center bg-transparent pointer-events-none z-10 ">
            <div className="space-y-2">
              <p
                className="text-center text-ptxt font-bold"
                style={{
                  fontSize: `${getInvertedSize(titleValue.toString())}px`,
                }}
              >
                {titleValue.toLocaleString("en-US")}
              </p>
              <p className="text-center text-ptxt font-light">{titleText}</p>
            </div>
          </div>
          <CanvasJSChart options={options} />
        </div>
      )}
      <div className="h-full grid place-items-center">
        <div
          className={`grid ${
            data.length > 3 ? "grid-cols-2" : ""
          } gap-x-[20%] gap-y-9`}
        >
          {data.map((item, index) => (
            <div key={index} className="flex gap-2">
              <div
                className="aspect-square h-4 mt-1"
                title={item?.labelKey}
                style={{
                  backgroundColor: item?.color,
                }}
              ></div>
              <div>
                <p className="font-light">{item?.labelKey}</p>
                <p className="font-bold text-lg">{item?.labelValue}</p>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default DoughnutChart;

function getInvertedSize(text) {
  const maxSize = 40; // Maximum size
  const minSize = 25; // Minimum size
  const maxLength = 10; // Length at which the size is minimum

  const size = Math.max(
    minSize,
    maxSize - (text.length * (maxSize - minSize)) / maxLength
  );
  return size;
}
