import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";

const useDoQuickTopUp = () => {
  const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_QUICK_TOP_UP;
  const { makeApiCall } = useApi();

  const doQuickTopUp = async (dataList) => {
    try {
      const response = await makeApiCall({
        url,
        method: "POST",
        data: {
          data: dataList,
        },
      });
      return {
        status: response?.data?.status,
        message: response?.data?.message,
      };
    } catch (error) {
      // console.log(error);
      return { status: false, message: error.message };
    }
  };

  const doQuickTopUpSingleMsisdn = async (data) =>
    await doQuickTopUp([{ ...data }]);

  const formatRequestData = (msisdn, operator, amount) => {
    return {
      msisdn: msisdn,
      operator: operator,
      amount: amount,
    };
  };
  return { doQuickTopUp, doQuickTopUpSingleMsisdn, formatRequestData };
};

export default useDoQuickTopUp;
