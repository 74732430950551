import ApiConstants from "../constants/ApiConstants";
import { useUser } from "../features/UserSlice";
import useApi from "./useApi";

const useUpdateUserProfile = () => {
  const { user, saveUser } = useUser();
  const { makeApiCall, loading } = useApi();
  const updateUserProfile = async (data) => {
    const url = `${ApiConstants.BASE_URL}/api/user/update-profile`;
    try {
      const response = await makeApiCall({
        url,
        method: "POST",
        data,
      });
      const success = response.data.status;
      if (success) {
        saveUser({ ...user, ...data });
      }
      return success;
    } catch (error) {
      // console.log(error);
    }
  };
  return { updateUserProfile, loading };
};

export default useUpdateUserProfile;
