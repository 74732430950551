import React from "react";
import StatsSection from "./Stats";
import Support from "./Support";
import SalesOverview from "./SalesOverview";
import MyBalance from "./MyBalance";
import MobileTopup from "./MobileTopup";

const Dashboard = () => {
  return (
    <div>
      <DashboardHeader />
      <div className="grid tablet:grid-cols-2 gap-12">
        <MyBalance />
        <MobileTopup />
      </div>
      <SalesOverview />
      <StatsSection />
      <Support />
    </div>
  );
};

export default Dashboard;

const DashboardHeader = () => {
  return (
    <div className="space-y-1 mb-6">
      <p className="font-medium text-[1.75em] leading-5">Dashboard</p>
      <p className="font-light text-lg text-stxt">
        Welcome to BalanceBoost Dashboard
      </p>
    </div>
  );
};
