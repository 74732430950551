import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import backArrow from "../../assets/svgs/back-arrow.svg";
import uploadFileIcon from "../../assets/svgs/upload-file-icon.svg";
import Error_ICON from "../../assets/svgs/error_icon.svg";
import fileIcon from "../../assets/svgs/file-icon.svg";
import PATHS from "../../constants/RoutePaths";
import validateMsisdn from "../../functions/validateMsisdn";
import Papa from "papaparse"; // Import PapaParse for CSV parsing
import useApp from "../../context/AppState";
import requiredHeaders from "../../constants/CsvRequiredHeaders";
import Loader from "../../reusable/Loader";
import crossIconSmall from "../../assets/svgs/cross-icon-small.svg";
import { getNetwork } from "../../data/Networks";
import { useAuth } from "../../features/AuthSlice";
import useGetFiles from "../../hooks/topup/files/useGetFiles";
import useUploadFile from "../../hooks/topup/files/useUploadFile";
import formatDate from "../../functions/formatDate";
import useDeleteFile from "../../hooks/topup/files/useDeleteFile";
import TextWithLoader from "../../reusable/TextWithLoader";
import EnvConstants from "../../constants/EnvConstants";

const Files = () => {
  // File type check to allow only CSV and Spreadsheet files
  const validFileTypes = ["text/csv"];
  const { showPopup } = useApp();
  const navigate = useNavigate();
  const { deleteFile, loading: deleting } = useDeleteFile();

  const [file, setFile] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const {
    formattedFiles,
    fetchingFirst,
    getFiles,
    loading,
    loadedOnce,
    refreshInSeconds,
    hasProcessingFiles,
  } = useGetFiles();

  const handleFileChange = (event) => {
    setErrorMessage(null);
    const tempSelectedFile = event.target.files[0];
    if (tempSelectedFile && validFileTypes.includes(tempSelectedFile.type)) {
      if (
        formattedFiles.some((file) => file?.filename === tempSelectedFile?.name)
      ) {
        setErrorMessage("File with same name already exists.");
      } else {
        setFile(tempSelectedFile);
      }
      setSelectedFile(null);
    }
  };

  const handleDrop = (event) => {
    event.preventDefault();
    setErrorMessage(null);
    const droppedFile = event.dataTransfer.files[0];
    if (droppedFile && validFileTypes.includes(droppedFile.type)) {
      if (formattedFiles.some((file) => file?.filename === droppedFile?.name)) {
        setErrorMessage("File with same name already exists.");
      } else {
        setFile(droppedFile);
      }
      setSelectedFile(null);
    } else {
      setErrorMessage("Please select a valid CSV or Spreadsheet file.");
      // alert("Please select a valid CSV or Spreadsheet file.");
      setFile(null); // Reset the file state if invalid
      setSelectedFile(null);
    }
  };

  const handleDragOver = (event) => {
    event.preventDefault();
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    if (!file) {
      return;
    }
    showPopup(
      <Popup
        file={file}
        setFile={setFile}
        setSelectedFile={setSelectedFile}
        getFiles={getFiles}
      />
    );
  };

  const fileInput = (
    <input
      id="file-upload"
      type="file"
      onChange={handleFileChange}
      className="hidden"
      accept=".csv" // Accept only CSV and spreadsheet files
    />
  );
  return (
    <section className="space-y-5">
      <div className="flex gap-6 items-center">
        <Link to={PATHS.TOPUP}>
          <img className="size-[1.625em]" src={backArrow} alt="<" />
        </Link>
        <p className="heading-text">{`Upload File`}</p>
      </div>
      <div className="file-view-upload-container">
        <form onSubmit={handleSubmit} className="w-full bg-sbg p-7 space-y-11">
          <div className="w-full heading-text-2">
            {selectedFile ? "View File" : "Upload new file"}
          </div>
          <div className="flex justify-center w-full">
            {selectedFile ? (
              <div className="px-20 py-5 rounded-lg mb-4 text-center">
                <div className="cursor-pointer text-ptxt flex flex-col items-center gap-6">
                  <img className="w-14" src={fileIcon} alt="" />
                  <p className="text-xl">{selectedFile?.filename}</p>
                </div>
                <p className="text-transparent text-center mt-8 mb-7 text-sm select-none">
                  .
                </p>
                <div className="flex justify-center w-full gap-5">
                  <label htmlFor="file-upload" className="btn-secondary">
                    Change File
                  </label>
                  {fileInput}
                  <div
                    onClick={() =>
                      navigate(PATHS.TOPUP_FILES_VIEW_FILE, {
                        state: { file: selectedFile },
                      })
                    }
                    className="btn"
                  >
                    <span className="select-none px-5">Next</span>
                  </div>
                </div>
              </div>
            ) : file ? (
              <div className="px-20 py-5 rounded-lg mb-4 text-center">
                {fileInput}
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer text-ptxt flex flex-col items-center gap-6"
                >
                  <img className="w-14" src={fileIcon} alt="" />
                  <p className="text-xl">{file?.name}</p>
                </label>
                <p className="text-transparent text-center mt-8 mb-7 text-sm select-none">
                  .
                </p>
                <div className="flex justify-center w-full gap-5">
                  <label htmlFor="file-upload" className="btn-secondary">
                    Change File
                  </label>
                  <button className="btn">
                    <span className="select-none px-5">Upload</span>
                  </button>
                </div>
              </div>
            ) : (
              <div
                className=" border-transparent hover:border-brdr px-20 py-5 rounded-lg mb-4 border-2 border-dashed"
                onDrop={handleDrop}
                onDragOver={handleDragOver}
              >
                {fileInput}
                <label
                  htmlFor="file-upload"
                  className="cursor-pointer text-ptxt flex flex-col items-center gap-6"
                >
                  <img className="size-12" src={uploadFileIcon} alt="" />
                  Drag and Drop File
                </label>
                <p className="text-stxt text-center mt-8 mb-7 text-sm">OR</p>
                <div className="flex justify-center w-full">
                  <label htmlFor="file-upload" className="btn">
                    Choose File
                  </label>
                </div>
                {errorMessage && (
                  <p className="text-cpink text-sm text-center mt-3">
                    {errorMessage}
                  </p>
                )}
              </div>
            )}
          </div>
        </form>
        <div className="bg-sbg p-7 min-h-[45vh] h-[40vh] overflow-y-auto custom-scrollbar space-y-4">
          {
            <div className="w-full flex justify-between items-center">
              <p className="flex-grow heading-text-2">Saved Files</p>
              <p>
                {hasProcessingFiles && !loadedOnce
                  ? loading
                    ? "Refreshing"
                    : `Refreshing in ${refreshInSeconds} seconds...`
                  : null}
              </p>
            </div>
          }
          {fetchingFirst ? (
            <div className="flex w-full h-[20vh] justify-center items-center">
              <p>Loading...</p>
            </div>
          ) : !loading && formattedFiles.length === 0 && loadedOnce ? (
            <div>No Files Uploaded.</div>
          ) : (
            <div
              className={`text-stxt ${deleting ? "pointer-events-none" : ""}`}
            >
              {formattedFiles.map((item, index) => {
                return (
                  <div key={index}>
                    <div
                      className={`grid grid-cols-6 py-3 px-1 rounded-md hover:bg-tbg ${
                        selectedFile?.fileId === item?.fileId ? "bg-tbg" : ""
                      }
                      ${item.className}`}
                      title={item?.status}
                    >
                      <div className="col-span-3 text-[1.125em] leading-5">
                        <span
                          disabled={!item.isClickable}
                          className="cursor-pointer hover:underline"
                          onClick={() => {
                            if (item.isClickable) {
                              setSelectedFile(item);
                            }
                          }}
                        >
                          {item?.filename}
                        </span>
                      </div>
                      <TextWithLoader className="text-xs" text={item?.status} showLoader={item?.isProcessing} />
                      <div className="text-xs">
                        {formatDate(item?.uploadTime)}
                      </div>
                      <div className="flex justify-end px-5">
                        <div
                          onClick={async () => {
                            // eslint-disable-next-line no-restricted-globals
                            if (confirm("Are you Sure?")) {
                              const result = await deleteFile(item.fileId);
                              if (result) {
                                getFiles();
                              }
                            }
                          }}
                          className="cursor-pointer hover:scale-110"
                          title="Delete"
                        >
                          <img
                            src={crossIconSmall}
                            className="size-[13px]"
                            alt="Delete"
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })}
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

export default Files;

const Popup = ({ file, setFile, setSelectedFile, getFiles }) => {
  const [error, setError] = useState(false);
  const [uploading, setUploading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [progress, setProgress] = useState(0);
  const { getNetworkRuleByName } = useAuth();
  const uploadFile = useUploadFile();

  const { closePopup } = useApp();

  useEffect(() => {
    const processCSV = async () => {
      const reader = new FileReader();

      reader.onload = async (e) => {
        const csvData = Papa.parse(e.target.result, { header: true });
        const parsedData = csvData.data;
        const fileHeaders = csvData.meta.fields;

        const hasValidHeaders = requiredHeaders.every((header) =>
          fileHeaders.includes(header)
        );

        if (!hasValidHeaders) {
          setError(true);
          setErrorMessage(
            `Invalid CSV format. The file should contain the following headers: ${requiredHeaders.join(
              ", "
            )}`
          );
          return;
        }

        if (!EnvConstants.TOP_UP_FILE_UPLOAD_ALLOW_DUPLICATES) {
          const hasDuplicates = parsedData
            .map((item) => item.MSISDN)
            .some((msisdn, index, arr) => arr.indexOf(msisdn) !== index);
  
          if (hasDuplicates) {
            setError(true);
            setErrorMessage(
              `Invalid CSV format. Duplicate MSISDNs entries found. Please update and retry.`
            );
            return;
          }
        }

        let isError = false;
        let errorMessage = "";
        for (let index = 0; index < parsedData.length; index++) {
          const prog = Math.ceil(((index + 1) * 100) / parsedData.length);
          setProgress(prog); // Update progress state

          // Delay to allow re-render
          await new Promise((resolve) => setTimeout(resolve, 1)); // Adjust the delay as needed

          const entry = parsedData[index];

          // Handle the last empty entry if necessary
          if (index === parsedData.length - 1) {
            if (
              (entry?.[requiredHeaders[0]] === "" ||
                entry?.[requiredHeaders[0]] === null ||
                entry?.[requiredHeaders[0]] === undefined) &&
              (entry?.[requiredHeaders[1]] === "" ||
                entry?.[requiredHeaders[1]] === null ||
                entry?.[requiredHeaders[1]] === undefined)
            ) {
              parsedData.pop();
              continue;
            }
          }
          if (!validateMsisdn(entry?.[requiredHeaders[0]])) {
            isError = true;
            errorMessage = `Invalid Msisdn at line ${
              index + 2
            }. Invalid Format. Please update and retry.`;
          }
          const network = getNetwork(entry?.[requiredHeaders[0]]);
          const networkRules = getNetworkRuleByName(network?.networkName);
          if (!network) {
            isError = true;
            errorMessage = `Invalid Msisdn at line ${
              index + 2
            }. No Network found. Please update and retry.`;
          } else if (isNaN(entry?.[requiredHeaders[1]])) {
            isError = true;
            errorMessage = `Invalid CSV format. Non Numeric value found at line ${
              index + 2
            }. Please update and retry.`;
          } else if (
            Number(entry?.[requiredHeaders[1]]) < networkRules?.minCreditLimit
          ) {
            isError = true;
            errorMessage = `Invalid CSV format. Amount must be greater than or equal to ${
              networkRules?.minCreditLimit
            } at line ${index + 2}. Please update and retry.`;
          } else if (
            Number(entry?.[requiredHeaders[1]]) > networkRules?.maxCreditLimit
          ) {
            isError = true;
            errorMessage = `Invalid CSV format. Amount must be less than or equal to ${
              networkRules?.maxCreditLimit
            } at line ${index + 2}. Please update and retry.`;
          }
          if (isError) {
            break;
          }
        }
        if (isError) {
          setError(true);
          setErrorMessage(errorMessage);
          setFile(null);
        } else {
          // If the format is valid, proceed with processing the CSV data
          // console.log("CSV Data:", parsedData); // Log the parsed CSV data
          setUploading(true);
          const result = await uploadFile(file);
          if (result) {
            setUploading(false);
            setFile(null);
            getFiles();
            closePopup();
          }
        }
      };

      reader.readAsText(file); // Read the CSV file as text
    };

    if (file) {
      processCSV(); // Trigger CSV processing when the file is available
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const onCloseError = () => {
    closePopup();
    window.location.reload();
  };

  return error ? (
    <div className="bg-sbg rounded-md px-28 py-12 flex flex-col items-center gap-16 max-w-[80vw] tablet:max-w-[40vw]">
      <div>
        <img className="w-11" src={Error_ICON} alt="Error" />
      </div>
      <div className="text-center">{errorMessage}</div>
      <div className="w-full-flex justify-center">
        <button
          className="btn-secondary rounded-lg hover:scale-105 transition-all duration-700"
          onClick={onCloseError}
        >
          Close
        </button>
      </div>
    </div>
  ) : !uploading ? (
    <div className="bg-sbg rounded-md px-28 py-12 flex flex-col items-center gap-16">
      <div>
        <p className="text-3xl">File Processing...</p>
      </div>
      <div>
        <p className="text-6xl">{progress}%</p>
      </div>
      <div className="space-y-9">
        <div className="w-[30vw] bg-tbg rounded-full h-2.5 ">
          <div
            className="bg-ttxt text-transparent select-none h-full rounded-full transition-all"
            style={{
              width: `${progress}%`,
            }}
          ></div>
        </div>
        <div>
          <p className="text-center text-stxt">
            Please hold on, we are preparing the List.
          </p>
        </div>
      </div>
    </div>
  ) : (
    <div className="bg-sbg rounded-md px-28 py-12 flex flex-col items-center gap-16">
      <div>
        <p className="text-3xl">Uploading...</p>
      </div>
      <div>
        <Loader />
      </div>
      <div>
        <p className="text-center text-stxt">
          Please wait on, we are uploading the List.
        </p>
      </div>
    </div>
  );
};
