import signupPasswordPageImage from "../assets/images/signup-page-image.png";
import loginPageImage from "../assets/images/login-page-image.png";
import integrateTechnologyImage from "../assets/images/integrate-technology-image.png";

const AuthPageLeftContent = [
  {
    imgSrc: signupPasswordPageImage,
    title: "Join the Platform!",
    description:
      "Sign up to start sending mobile balance effortlessly to any telecom operator. Perfect for businesses and individuals looking for reliable and efficient balance distribution.",
  },
  {
    imgSrc: loginPageImage,
    title: "Log in to your Account",
    description:
      " Easily send mobile balance to any number, across any telecom operator in the country. Our platform is designed for businesses and individuals alike, offering seamless, efficient, and reliable balance transfer services.",
  },
  {
    imgSrc: integrateTechnologyImage,
    title: "Integrate Technology",
    description:
      "Take control of your operations with our powerful API, designed to integrate seamlessly with your existing systems. Whether you need to automate processes or enhance your application’s capabilities, our API ensures smooth and efficient balance transfers, available whenever and wherever you need it.",
  },
];

export default AuthPageLeftContent;
