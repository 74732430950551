import React from "react";
import IconTitleComponent from "./IconTitleComponent";
import EnvConstants from "../../constants/EnvConstants";
import supportIcon from "../../assets/svgs/support-icon.svg";

const Support = () => {
  return (
    <div className="mt-7 text-center px-11 py-9 rounded-md bg-sbg space-y-6">
      <div className="flex justify-center">
        <IconTitleComponent iconSrc={supportIcon} title={"Support"} />
      </div>
      <div className="space-y-5">
        <p className="font-light text-xl text-stxt">
          Please reach out to our support team, available 24/7.
        </p>
        <div className="space-y-2">
          <div className="flex items-center justify-center gap-2 text-xl text-stxt">
            <p>Email: </p>
            <a
              href={`mailto:${EnvConstants.SUPPORT_EMAIL}`}
              className="text-xl text-ttxt hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {EnvConstants.SUPPORT_EMAIL}
            </a>
          </div>
          <div className="flex items-center justify-center gap-2 text-xl text-stxt">
            <p>Phone: </p>
            <a
              href={`tel:${EnvConstants.SUPPORT_PHONE_NO}`}
              className="text-xl text-ttxt hover:underline"
              target="_blank"
              rel="noreferrer"
            >
              {EnvConstants.SUPPORT_PHONE_NO}
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Support;
