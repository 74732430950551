/* eslint-disable no-unused-vars */
import { useEffect, useMemo } from "react";
import { useState } from "react";
import useApi from "../useApi";
import ApiConstants from "../../constants/ApiConstants";
import { findTransactionStatus } from "../../constants/wallet/TransactionStatuses";
import useHandlePage from "../useHandlePage";

const useGetWalletTransactions = (
  initialState = {
    page: 0,
    size: 5,
  }
) => {
  const [data, setData] = useState([]);
  const [dataInfo, setDataInfo] = useState([]);
  const [filter, setFilterState] = useState(initialState);
  const { makeApiCall, loading } = useApi();

  const formattedData = useMemo(
    () =>
      data.map((item) => ({
        ...item,
        status: findTransactionStatus(item?.statusCode),
      })),
    [data]
  );

  const updateFilter = (page, size) => {
    setFilterState({
      page,
      size,
    });
  };

  const getWalletTransactions = async () => {
    if (!filter) {
      return;
    }
    try {
      const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_WALLET_GET_TRANSCACTIONS}`;
      const response = await makeApiCall({
        url,
        params: {
          page: filter?.page,
          size: filter?.size,
        },
      });
      /*   
          const response = {
            data: {
              data: getMockData(filter?.page, filter?.size),
            },
          };
      */
      const { data } = response?.data;
      setData(Array.isArray(data?.content) ? data?.content : []);
      setDataInfo({
        totalEntries: data?.totalEntries,
        totalPages: data?.totalPages,
      });
    } catch (error) {
      // console.log(error);
    }
  };

  useEffect(() => {
    if (filter) {
      getWalletTransactions();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  const { page, setPage, pageSize, setPageSize } = useHandlePage(
    filter,
    setFilterState
  );

  return {
    data,
    formattedData,
    totalEntries: dataInfo?.totalEntries,
    totalPages: dataInfo?.totalPages,
    updateFilter,
    loading,
    refreshTransactionsData: getWalletTransactions,
    page,
    setPage,
    pageSize,
    setPageSize,
  };
};

export default useGetWalletTransactions;

