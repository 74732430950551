import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";
import mockData from "../../mock/topup-client-charts-api-mockup.json";

const useGetTotalTransactionRateStats = () => {
  const { makeApiCall } = useApi();

  const getTotalTransactionRateStats = async (noOfDays = 7) => {
    const url = `${ApiConstants.BASE_URL}/api/reports/success-and-failed-transaction-rate?days=${noOfDays}`;
    try {
      const response = await makeApiCall({
        url,
      });
      const { data } = response?.data;

      return data;
    } catch (error) {
      // console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getTotalTransactionRateStats1 = async (noOfDays = 7) => {
    const { SUCCESS_AND_FAILED_TRANSACTION_RATE, MOCK_FETCH_DELAY_IN_MILLIS } = mockData;
    const { response } = SUCCESS_AND_FAILED_TRANSACTION_RATE;

    // Wait for 3 seconds
    await new Promise((resolve) => setTimeout(resolve, MOCK_FETCH_DELAY_IN_MILLIS));
    return response;
  };
  return getTotalTransactionRateStats;
};

export default useGetTotalTransactionRateStats;
