import { useEffect, useState } from "react";
import useApi from "../useApi";
import ApiConstants from "../../constants/ApiConstants";

const useGetRates = () => {
  const [data, setData] = useState([]);
  const [error, setError] = useState(null);
  const { makeApiCall, loading } = useApi();

  const getRates = async () => {
    const url = `${ApiConstants.BASE_URL}/api/config/bundles`;
    try {
      const response = await makeApiCall({
        url,
      });
      const { data } = response?.data;
      setData(data);
    } catch (error) {
      console.error(error);
      setError(error);
    }
  };

  const getTariffPercentage = (amount, tariffs) => {
    for (let i = 0; i < tariffs.length; i++) {
      const { minAmount, maxAmount, percentage } = tariffs[i];
      if (amount >= minAmount && (maxAmount === null || amount <= maxAmount)) {
        return percentage;
      }
    }
    return 0; // return 0 if no tariff matches
  };

  const getPercentage = (amount) => {
    const tariffs = data;
    return getTariffPercentage(amount, tariffs);
  };

  useEffect(() => {
    getRates();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return { data, loading, error, getTariffPercentage, getPercentage };
};

export default useGetRates;
