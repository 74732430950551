import { useUser } from "../../features/UserSlice";
import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";

const useUpdateNotificationSettings = () => {
  const {
    updateNotifyLowBalanceByEmail,
    updateBalanceThreshold,
    notifyUserByEmailBalanceThreshold,
  } = useUser();
  const { makeApiCall, loading } = useApi();
  const url =
    ApiConstants.BASE_URL + ApiConstants.ENDPOINT_UPDATE_NOTIFICATION_SETTINGS;

  const updateNotificationSettings = async (val, balanceThreshold) => {
    try {
      const response = await makeApiCall({
        url,
        method: "POST",
        data: {
          balanceThreshold:
            balanceThreshold || notifyUserByEmailBalanceThreshold,
          notifyOnBalanceLow: !!val,
        },
      });
      const { data } = response?.data;
      updateNotifyLowBalanceByEmail(data?.notifyOnBalanceLow);
      updateBalanceThreshold(data?.balanceThreshold);
    } catch (error) {
      // console.log(error);
    }
  };
  return { updateNotificationSettings, loading };
};

export default useUpdateNotificationSettings;
