import { createSlice } from "@reduxjs/toolkit";
import { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import useGetTotalTransactionStats from "../hooks/stats/useGetTotalTransactionStats";
import useGetTotalTransactionCountStats from "../hooks/stats/useGetTotalTransactionCountStats";
import useGetTotalTransactionOperatorWiseStats from "../hooks/stats/useGetTotalTransactionOperatorWiseStats";
import useGetTotalTransactionRateStats from "../hooks/stats/useGetTotalTransactionRateStats";

const statsState = {
  totalTransactions: {
    loadedOnce: false,
    loading: false,
    data: {
      totalSent: 0,
      totalReceived: 0,
      dataSet: [],
    },
  },
  totalTransactionsCount: {
    loadedOnce: false,
    loading: false,
    data: [],
  },
  totalTransactionsOperatorWise: {
    loadedOnce: false,
    loading: false,
    data: {
      totalSent: 0,
      dataSet: [],
    },
  },
  totalTransactionsStatusWise: {
    loadedOnce: false,
    loading: false,
    data: {
      totalTransactionsCount: 0,
      successfulTransactionsCount: 0,
      failedTransactionsCount: 0,
    },
  },
};

const StatsOptionsObj = Object.freeze({
  weekly: {
    text: "View by Week",
    noOfDays: 7,
  },
  monthly: {
    text: "View by Month",
    noOfDays: 30,
  },
  quaterly: {
    text: "Last 90 days",
    noOfDays: 90,
  },
});

const StatsOptions = Object.freeze(Object.keys(StatsOptionsObj));

const statsFilterOptions = Object.freeze(
  Object.entries(StatsOptionsObj).map(([key, value]) => ({
    label: value.text,
    value: key,
  }))
);

const initialState = Object.freeze({
  filter: StatsOptions[0],
  ...StatsOptions.reduce((acc, key) => {
    acc[key] = statsState;
    return acc;
  }, {}),
});

export const statsSlice = createSlice({
  name: "stats",
  initialState,
  reducers: {
    updateFilterReducer: (state, action) => {
      state.filter = action.payload;
    },
    updateSliceReducer: (state, action) => {
      const { filter, filterTypeData } = action.payload;
      const { filterType, data } = filterTypeData;
      const { key, value } = data;
      state[filter] = {
        ...state[filter],
        [filterType]: {
          ...state[filter][filterType],
          [key]: value,
        },
      };
    },
  },
});

const { updateFilterReducer, updateSliceReducer } = statsSlice.actions;

export const useStats = (loadData = false) => {
  const slice = useSelector((state) => state.memory.stats);
  const dispatch = useDispatch();
  const getTotalTransactionStats = useGetTotalTransactionStats();
  const getTotalTransactionCountStats = useGetTotalTransactionCountStats();
  const getTotalTransactionOperatorWiseStats =
    useGetTotalTransactionOperatorWiseStats();
  const getTotalTransactionRateStats = useGetTotalTransactionRateStats();

  const filter = slice.filter;
  const updateFilter = (filter) => dispatch(updateFilterReducer(filter));
  const noOfDays = StatsOptionsObj[filter].noOfDays;

  const updateSlice = (filterType, key, value) =>
    updateSliceOfFilter(filter, filterType, key, value);

  const updateSliceOfFilter = (filter, filterType, key, value) => {
    dispatch(
      updateSliceReducer({
        filter,
        filterTypeData: { filterType, data: { key, value } },
      })
    );
  };

  const setLoading = (key, value) => updateSlice(key, "loading", value);
  const setLoadedOnce = (key, value) => updateSlice(key, "loadedOnce", value);
  const setData = (key, value) => updateSlice(key, "data", value);

  const setLoadingTotalTransactions = (value) =>
    setLoading("totalTransactions", value);
  const setTotalTransactions = (data) => {
    const key = "totalTransactions";
    setLoadedOnce(key, true);
    setLoading(key, false);
    setData(key, data);
  };

  const setLoadingTotalTransactionsCount = (value) =>
    setLoading("totalTransactionsCount", value);
  const setTotalTransactionsCount = (data) => {
    const key = "totalTransactionsCount";
    setLoadedOnce(key, true);
    setLoading(key, false);
    setData(key, data);
  };

  /*const setLoadingTotalTransactionsOperatorWise = (value) =>
    setLoading("totalTransactionsOperatorWise", value);
  const setTotalTransactionsOperatorWise = (data) => {
    const key = "totalTransactionsOperatorWise";
    setLoadedOnce(key, true);
    setLoading(key, false);
    setData(key, data);
  };*/

  const setLoadingTotalTransactionsOperatorWise = (value) =>
    StatsOptions.forEach((filterKey) => {
      updateSliceOfFilter(
        filterKey,
        "totalTransactionsOperatorWise",
        "loading",
        value
      );
    });
  const setTotalTransactionsOperatorWise = (data) => {
    const key = "totalTransactionsOperatorWise";
    StatsOptions.forEach((filterKey) => {
      updateSliceOfFilter(filterKey, key, "loadedOnce", true);
      updateSliceOfFilter(filterKey, key, "loading", false);
      updateSliceOfFilter(filterKey, key, "data", data);
    });
  };




  const setLoadingTotalTransactionsStatusWise = (value) =>
    StatsOptions.forEach((filterKey) => {
      updateSliceOfFilter(
        filterKey,
        "totalTransactionsStatusWise",
        "loading",
        value
      );
    });
  const setTotalTransactionsStatusWise = (data) => {
    const key = "totalTransactionsStatusWise";
    StatsOptions.forEach((filterKey) => {
      updateSliceOfFilter(filterKey, key, "loadedOnce", true);
      updateSliceOfFilter(filterKey, key, "loading", false);
      updateSliceOfFilter(filterKey, key, "data", data);
    });
  };

  const statsData = slice[filter];
  const {
    loadedOnceTotalTransactions,
    loadingTotalTransactions,
    totalTransactions,

    loadedOnceTotalTransactionsCount,
    loadingTotalTransactionsCount,
    totalTransactionsCount,

    loadedOnceTotalTransactionsOperatorWise,
    loadingTotalTransactionsOperatorWise,
    totalTransactionsOperatorWise,

    loadedOnceTotalTransactionsStatusWise,
    loadingTotalTransactionsStatusWise,
    totalTransactionsStatusWise,
  } = getStatsFormatted(statsData);

  const fetchTotalTransactionsStats = async () => {
    if (!loadedOnceTotalTransactions && !loadingTotalTransactions) {
      setLoadingTotalTransactions(true);
      const data = await getTotalTransactionStats(noOfDays);
      if (data) {
        setTotalTransactions(data);
      }
      setLoadingTotalTransactions(false);
    }
  };

  const fetchTotalTransactionsCountStats = async () => {
    if (!loadedOnceTotalTransactionsCount && !loadingTotalTransactionsCount) {
      setLoadingTotalTransactionsCount(true);
      const data = await getTotalTransactionCountStats(noOfDays);
      if (data) {
        setTotalTransactionsCount(data);
      }
    }
  };

  const fetchTotalTransactionsOperatorWiseStats = async () => {
    if (
      !loadedOnceTotalTransactionsOperatorWise &&
      !loadingTotalTransactionsOperatorWise
    ) {
      setLoadingTotalTransactionsOperatorWise(true);
      const data = await getTotalTransactionOperatorWiseStats(noOfDays);
      if (data) {
        setTotalTransactionsOperatorWise(data);
      }
      setLoadingTotalTransactionsOperatorWise(false);
    }
  };

  const fetchTotalTransactionsStatusWiseStats = async () => {
    if (
      !loadedOnceTotalTransactionsStatusWise &&
      !loadingTotalTransactionsStatusWise
    ) {
      setLoadingTotalTransactionsStatusWise(true);
      const data = await getTotalTransactionRateStats(noOfDays);
      if (data) {
        setTotalTransactionsStatusWise(data);
      }
      setLoadingTotalTransactionsStatusWise(false);
    }
  };

  const fetchStats = () => {
    fetchTotalTransactionsStats();
    fetchTotalTransactionsCountStats();
    fetchTotalTransactionsOperatorWiseStats();
    fetchTotalTransactionsStatusWiseStats();
  };

  useEffect(() => {
    if (loadData) {
      fetchStats();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return {
    noOfDays,
    filter,
    updateFilter,
    statsFilterOptions,
    loadingTotalTransactions,
    totalTransactions,
    loadingTotalTransactionsCount,
    totalTransactionsCount,
    loadingTotalTransactionsOperatorWise,
    totalTransactionsOperatorWise,
    loadingTotalTransactionsStatusWise,
    totalTransactionsStatusWise,
  };
};

export default statsSlice.reducer;

const getStatsFormatted = (statsData) => {
  const {
    totalTransactions,
    totalTransactionsCount,
    totalTransactionsOperatorWise,
    totalTransactionsStatusWise,
  } = statsData;

  // Handeling Total Transactions

  const {
    loadedOnce: loadedOnceTotalTransactions,
    loading: loadingTotalTransactions,
    data: totalTransactionsData,
  } = totalTransactions;

  // Handeling Total Transactions Count

  const {
    loadedOnce: loadedOnceTotalTransactionsCount,
    loading: loadingTotalTransactionsCount,
    data: totalTransactionsCountData,
  } = totalTransactionsCount;

  // Handeling Total Transactions Operator Wise

  const {
    loadedOnce: loadedOnceTotalTransactionsOperatorWise,
    loading: loadingTotalTransactionsOperatorWise,
    data: totalTransactionsOperatorWiseData,
  } = totalTransactionsOperatorWise;

  // Handeling Total Transactions Status Wise

  const {
    loadedOnce: loadedOnceTotalTransactionsStatusWise,
    loading: loadingTotalTransactionsStatusWise,
    data: totalTransactionsStatusWiseData,
  } = totalTransactionsStatusWise;

  return {
    loadedOnceTotalTransactions,
    loadingTotalTransactions,
    totalTransactions: totalTransactionsData,

    loadedOnceTotalTransactionsCount,
    loadingTotalTransactionsCount,
    totalTransactionsCount: totalTransactionsCountData,

    loadedOnceTotalTransactionsOperatorWise,
    loadingTotalTransactionsOperatorWise,
    totalTransactionsOperatorWise: totalTransactionsOperatorWiseData,

    loadedOnceTotalTransactionsStatusWise,
    loadingTotalTransactionsStatusWise,
    totalTransactionsStatusWise: totalTransactionsStatusWiseData,
  };
};
