import React from "react";
import planetBeyondLogo from "../assets/images/planet-beyond-logo-light.png";
import balanceBoostLogo from "../assets/images/BalanceBoost.png";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
const AuthPageComponent = ({
  leftContent = [],
  titleRight,
  descriptionRight,
  children,
}) => {
  return (
    <section className="h-svh w-full grid grid-cols-2">
      <section className="flex flex-col items-center w-full bg-sbg px-12 py-10 ">
        <div className="w-full mb-6">
          <img src={planetBeyondLogo} className="h-11" alt="" />
        </div>
        <Carousel
          autoPlay={true} // Enable auto-scrolling
          interval={2500} // Set the interval to 3000ms (3 seconds)
          infiniteLoop={true} // Enable infinite looping
          showStatus={false} // Hide the status
          showArrows={false} // Hide arrows
          showIndicators={leftContent.length > 1} // Hide indicators
          showThumbs={false} // Hide thumbnails
          dynamicHeight={false} // Disable dynamic height
          width={"40.78125vw"} // Set the width of the carousel
        >
          {leftContent.map((content, index) => (
            <div
              key={index}
              className=" grid place-items-center text-center mb-16"
            >
              <div className="w-[30.78125vw]">
                <div className="w-full flex justify-center">
                  <img
                    src={content?.imgSrc}
                    className="h-[60vh] aspect-square object-contain"
                    alt=""
                  />
                </div>
                <div className="space-y-6">
                  <p className="font-semibold text-[1.625em] leading-5">
                    {content?.title}
                  </p>
                  <p className="font-light">{content?.description}</p>
                </div>
              </div>
            </div>
          ))}
        </Carousel>
      </section>
      <section className="grid place-items-center">
        <div className="space-y-14 w-[25vw]">
          <div className="space-y-4">
            {/* <TitleText /> */}
            <TitleImg />
            <div className="space-y-1">
              <p className="font-semibold text-4xl">{titleRight}</p>
              <p className="text-lg text-stxt">{descriptionRight}</p>
            </div>
          </div>
          {children}
        </div>
      </section>
    </section>
  );
};

export default AuthPageComponent;

// eslint-disable-next-line no-unused-vars
const TitleText = () => {
  const appTitle = "balanceboost";
  const appTitleInvertIndex = 7;
  return (
    <div>
      {Array.from({ length: appTitle.length }, (_, index) => {
        const char = appTitle.charAt(index);
        const isInverted = index < appTitleInvertIndex;
        return (
          <div
            key={index}
            className="relative inline-block text-ttxt text-[2.73125em] uppercase "
          >
            <div className="bg-transparent font-extrabold">{char}</div>
            <div
              className={`bg-transparent absolute top-1/2 left-1/2 -translate-x-1/2 -translate-y-1/2 ${
                isInverted ? "text-pbg" : ""
              } text-[0.95em]`}
            >
              {char}
            </div>
          </div>
        );
      })}
    </div>
  );
};

const TitleImg = () => {
  return <img src={balanceBoostLogo} className="h-10" alt="" />;
};
