import React from "react";
import Footer from "../components/Footer";
import SideBar from "../components/SideBar";
import { Outlet } from "react-router-dom";

const Home = () => {
  return (
    <div className="h-svh flex flex-col text-sm laptop:text-base">
      <div className="flex-grow flex flex-col tablet:flex-row  overflow-hidden">
        <SideBar />
        <section className="flex-grow p-4 tablet:px-12 tablet:py-6 overflow-y-auto custom-scrollbar ">
          <Outlet />
        </section>
      </div>
      <Footer />
    </div>
  );
};

export default Home;
