import { useEffect, useMemo, useState } from "react";
import {
  findReportType,
  // REPORT_TYPES,
} from "../../constants/Reports/ReportTypes";
import {
  findReportStatus,
  LoadingReportStatuses,
  REPORT_STATUSES,
} from "../../constants/Reports/ReportsStatus";
import formatAmount from "../../functions/formatAmount";
import formatDate from "../../functions/formatDate";
import useApi from "../useApi";
import ApiConstants from "../../constants/ApiConstants";
import { Filters } from "../../constants/Reports/ReportsFilters";
import useUpdateUserBalance from "../useUpdateUserBalance";

const useGetReports = () => {
  const [reports, setReports] = useState([]);
  const [reportsCount, setReportsCount] = useState([]);
  const [filter, setFilterState] = useState(null);
  const [loadedOnce, setLoadedOnce] = useState(false);
  const { makeApiCall, loading } = useApi();
  const updateUserBalance = useUpdateUserBalance();

  const setFilter = (filter, page, size) => {
    setFilterState({
      filter: filter,
      page: page,
      size: size,
    });
  };

  useEffect(() => {
    if (filter) {
      fetchReports();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  useEffect(() => {
    const hasProcessing = reports.some((report) =>
      LoadingReportStatuses.includes(report?.statusCode)
    );

    if (hasProcessing) {
      const interval = setInterval(() => {
        fetchReports();
      }, 3000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reports]);

  const reportsFormatted = useMemo(
    () =>
      reports.map((report) => ({
        ...report,
        amount: formatAmount(report.amount),
        hasDetails:
          report?.statusCode !== REPORT_STATUSES.PENDING.value &&
          report?.statusCode !== REPORT_STATUSES.PAUSED.value,
        date: formatDate(report?.createdAt),
        type: findReportType(report?.requestTypeCode),
        status: findReportStatus(report?.statusCode),
      })),
    [reports]
  );

  const fetchReports = async () => {
    // Processing Filter
    let reportType = null;
    let reportStatus = null;
    switch (Number(filter?.filter?.filterType)) {
      case Filters.PROCESSED_TRANSACTIONS:
        reportStatus = REPORT_STATUSES.PROCESSED;
        break;
      case Filters.SINGLE_MSISDN_TYPE_TRANSACTIONS:
        reportType = 1; //REPORT_TYPES.SINGLE_MSISDN;
        break;
      case Filters.MULTIPLE_TYPE_TRANSACTIONS:
        reportType = 2; //REPORT_TYPES.MULTIPLE_MSISDN;
        break;
      case Filters.LIST_TYPE_TRANSACTIONS:
        reportType = 3; //REPORT_TYPES.LIST;
        break;
      default:
        break;
    }
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_GET_REPORTS}`;
    const params = {
      page: filter.filter.transactionId ? null : filter?.page - 1,
      size: filter.filter.transactionId ? null : filter?.size,
      start_date: filter.filter.fromDate,
      end_date: filter.filter.toDate,
      request_type: reportType,
      status: reportStatus,
      transaction_id: filter.filter.transactionId || null,
    };

    // Update user balance
    updateUserBalance();

    try {
      const response = await makeApiCall({
        url,
        params,
      });
      const { data } = response?.data;
      setReports(data?.content || []);
      setReportsCount({
        count: data.totalElements,
        amount: null, // Not Fetched Yet
      });
    } catch (error) {
      // console.log(error);
    } finally {
      if (!loadedOnce) {
        setLoadedOnce(true);
      }
    }
  };

  return {
    reports,
    reportsCount,
    reportsFormatted,
    filter,
    setFilter,
    fetching: loading,
    loadedOnce,
    fetchingFirst: loading && !loadedOnce,
  };
};

export default useGetReports;
