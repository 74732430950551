import React, { useState, useEffect } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { useAuth } from "../features/AuthSlice";
import googleIcon from "../assets/svgs/google-icon.svg";
import PATHS from "../constants/RoutePaths";
import useGetToken from "../hooks/auth/useGetToken";
import {
  signInWithEmailAndPassword,
  signInWithPopup,
  GoogleAuthProvider,
} from "firebase/auth";
import { auth } from "../firebase";
import AuthenticationProvider from "../constants/AuthenticationProviders";
import useFirebase from "../hooks/useFirebase";
import CustomLink from "../reusable/CustomLink";
import InputField from "../reusable/InputField";
import emailIcon from "../assets/svgs/email-icon.svg";
import emailIconFocused from "../assets/svgs/email-icon-focused.svg";
import passwordIcon from "../assets/svgs/password-icon.svg";
import passwordIconFocused from "../assets/svgs/password-icon-focused.svg";
import AuthPageComponent from "../reusable/AuthPageComponent";
import AuthPageLeftContent from "../data/AuthPageLeftContent";

const Login = () => {
  const { isAuthenticated, setFirebaseUser } = useAuth();
  const { getFirebaseErrorMessage, sendVerificationEmailToUser } =
    useFirebase();
  const getToken = useGetToken();
  const navigate = useNavigate();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [loading, setLoading] = useState(false);
  const [message, setMessage] = useState(""); // To store the message from URL
  const [isError, setIsError] = useState(false);
  const location = useLocation();
  const messageQueryParamKey = "msg";
  const errorQueryParamKey = "error";
  // eslint-disable-next-line no-unused-vars
  const msgNotErrorErrorValue = 0;
  const msgIsErrorErrorValue = 1;

  // On component mount, check if there's a msg parameter in the URL
  useEffect(() => {
    const searchParams = new URLSearchParams(location.search);
    const msg = searchParams.get(messageQueryParamKey);
    if (msg) {
      setMessage(decodeURIComponent(msg)); // Unembed and decode the message
    }
    const isError = searchParams.get(errorQueryParamKey);
    if (isError) {
      // eslint-disable-next-line eqeqeq
      setIsError(isError == msgIsErrorErrorValue);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.search]);

  if (isAuthenticated) {
    return <Navigate to={PATHS.DASHBOARD} replace />;
  }

  const updateURLParams = (msg, isError) => {
    const searchParams = new URLSearchParams(location.search);
    searchParams.set(messageQueryParamKey, encodeURIComponent(msg));
    searchParams.set(
      errorQueryParamKey,
      isError ? msgIsErrorErrorValue : msgNotErrorErrorValue
    );
    navigate({ search: searchParams.toString() });
  };

  const handleGeneralErrors = (error) => {
    const errorCode = error.code;
    const errorMessage = error.message;
    // console.log(`Error: \n\tCode: ${errorCode}\n\tMessage: ${errorMessage}`);
    let message = getFirebaseErrorMessage(errorCode);
    if (!message) {
      message = errorMessage;
    }
    updateURLParams(message, true);
    // updateURLParams(getFirebaseErrorMessage(errorCode), true);
    // console.log(getFirebaseErrorMessage(errorCode));
    // console.log(error);
    setLoading(false);
  };

  const onError = (error) => {
    if (error) {
      handleGeneralErrors(error);
    } else {
      handleGeneralErrors({
        code: 400,
        message: "An unexpected error has occurred. Please try again later.",
      });
    }
  };

  const handleLogin = async (e) => {
    e.preventDefault();
    if (loading) {
      return;
    }
    setLoading(true);
    signInWithEmailAndPassword(auth, email, password)
      .then((userCredential) => {
        // Signed In
        const user = userCredential.user;
        if (!user.emailVerified) {
          sendVerificationEmailToUser(user, updateURLParams);
          return;
        } else {
          setFirebaseUser(user);
          getToken(
            JSON.parse(JSON.stringify(user)),
            AuthenticationProvider.EMAIL_AND_PASSWORD,
            onError
          );
        }
      })
      .catch(handleGeneralErrors);
  };

  const handleGoogleLogin = () => {
    setLoading(true);
    const provider = new GoogleAuthProvider();
    signInWithPopup(auth, provider)
      .then((result) => {
        // User signed in successfully
        const user = result.user;
        if (!user.emailVerified) {
          sendVerificationEmailToUser(user, updateURLParams);
          return;
        }
        // console.log("User signed in:", user);
        setFirebaseUser(user);
        getToken(
          JSON.parse(JSON.stringify(user)),
          AuthenticationProvider.GOOGLE,
          onError
        );
        // Proceed with user data
      })
      .catch((error) => {
        if (error.code === "auth/popup-closed-by-user") {
          // Handle the case where user closed the popup
          // console.log("Popup closed by user");
          setLoading(false);
        } else {
          handleGeneralErrors(error);
        }
      });
  };

  return (
    <AuthPageComponent
      leftContent={AuthPageLeftContent}
      titleRight={"Log in to your Account"}
      descriptionRight={"Welcome back!"}
    >
      {/* Google Button <-- Start -->  */}
      <div
        onClick={handleGoogleLogin}
        className="bg-sbg w-full flex justify-between items-center cursor-pointer gap-1 py-3 px-6 rounded-md"
      >
        <div className="select-none">
          <img className="size-6" src={googleIcon} alt="" />
        </div>
        <div className="select-none text-stxt text-lg">Continue as Google</div>
        <div className="select-none"></div>
      </div>
      {/* Google Button <-- END -->  */}
      <div className="flex w-full justify-between items-center gap-4">
        <div className="flex-grow h-[1px] bg-stxt rounded-sm"></div>
        <div className="text-stxt">Or continue with email</div>
        <div className="flex-grow h-[1px] bg-stxt rounded-sm"></div>
      </div>
      <form className="w-full space-y-6" onSubmit={handleLogin}>
        {/* If there's a message from the URL, show it here */}
        {message && (
          <div
            className={`mb-4 text-center ${
              isError ? "text-cpink" : "text-cgreen"
            } bg-gray-700 p-2 rounded-md`}
          >
            {message}
          </div>
        )}
        <InputField
          icon={emailIcon}
          iconFocused={emailIconFocused}
          placeholder={"Email"}
          name={"email"}
          type={"email"}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          required
          disabled={loading}
        />
        <InputField
          icon={passwordIcon}
          iconFocused={passwordIconFocused}
          placeholder={"Password"}
          name={"password"}
          type={"password"}
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          disabled={loading}
          required={true}
        />
        <div className="flex justify-end">
          <CustomLink
            disabled={loading}
            to={PATHS.FORGOT_PASSWORD}
            className="text-lg text-ttxt hover:underline"
          >
            Forget Password?
          </CustomLink>
        </div>
        <div className="space-y-11">
          <div>
            <button
              disabled={loading}
              type="submit"
              className="btn w-full rounded-md"
            >
              {loading ? "Loading..." : "Login"}
            </button>
          </div>
          <div className="grid place-items-center text-center ">
            <div className="flex flex-wrap items-center text-lg">
              Don’t have an account?&nbsp;
              <CustomLink
                disabled={loading}
                to={PATHS.SIGN_UP}
                className="text-lg text-ttxt hover:underline"
              >
                Create Account
              </CustomLink>
            </div>
          </div>
        </div>
      </form>
    </AuthPageComponent>
  );
};

export default Login;
