export const REPORT_STATUSES = Object.freeze({
  PENDING: {
    value: 1,
    text: "In Queue",
    description: "The transaction is pending.",
    showLoader: true,
  },
  ALL_SUCCESS: {
    value: 2,
    text: "Success",
    description: "All top-ups in the transaction were successful.",
    className: "!text-cgreen"
  },
  PARTIAL_SUCCESS: {
    value: 3,
    text: "Partial Success",
    description: "Some top-ups in the transaction were successful.",
    className: "!text-cyellow"
  },
  ALL_FAILED: {
    value: 4,
    text: "Failed",
    description: "All top-ups in the transaction failed.",
    className: "!text-cpink"
  },
  PAUSED: {
    value: 5,
    text: "Paused (Will resume soon)",
    description: "Transaction is paused.",
    className: ""
  },
  CANCELED: {
    value: 6,
    text: "Canceled",
    description: "Transaction was canceled.",
    className: "!text-cpink"
  },
  PROCESSING: {
    value: 7,
    text: "Processing",
    description: "The transaction is being processed.",
    showLoader: true,
  },
});

const ALL_REPORTS_STATUS = Object.values(REPORT_STATUSES);

export default ALL_REPORTS_STATUS;

export function findReportStatus(value) {
  return (
    ALL_REPORTS_STATUS.find((report) => report.value === value) || {
      value: value,
      text: "Unknown",
      className: "",
    }
  );
}

export const LoadingReportStatuses = Object.freeze(ALL_REPORTS_STATUS.filter(reportStatus => reportStatus.showLoader).map((reportStatus) => reportStatus.value));