export function toTitleCase(str) {
  return str.replace(
    /\w\S*/g,
    (text) => text.charAt(0).toUpperCase() + text.substring(1).toLowerCase()
  );
}


export function convertCamelCaseToTitleCase(camelCaseString) {
  // Add a space before each uppercase letter and convert the string to lowercase
  let result = camelCaseString.replace(/([A-Z])/g, " $1").toLowerCase();

  // Capitalize the first letter of each word
  result = result.replace(/\b\w/g, function (char) {
    return char.toUpperCase();
  });

  return result;
}