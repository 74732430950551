import ApiConstants from "../../../constants/ApiConstants";
import useApi from "../../useApi";

const useUploadFile = () => {
  const { makeApiCall } = useApi();
  const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_UPLOAD_FILE;
  const uploadFile = async (file) => {
    const formData = new FormData();
    formData.append("file", file);
    try {
      const response = await makeApiCall({
        url,
        method: "POST",
        headers: {
          "Content-Type": "multipart/form-data",
        },
        data: formData,
      });
      // console.log(response.data);
      return response.status;
    } catch (error) {
      // console.log(error);
    }
  };
  return uploadFile;
};

export default useUploadFile;
