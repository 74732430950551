const EnvConstants = Object.freeze({
  REACT_STRICT_MODE_ENABLED: process.env.REACT_APP_USE_STRICT_MODE === "true",
  APP_SLUG: process.env.REACT_APP_APP_CONTEXT,
  COMPLEX_PASSWORD_FEATURE_ENABLED: process.env.REACT_APP_COMPLEX_PASSWORD_FEATURE_ENABLED === "true",
  MULTIPLE_MSISDN_TOP_UP_ALLOW_DUPLICATES: process.env.REACT_APP_MULTIPLE_MSISDN_TOP_UP_ALLOW_DUPLICATES === "true",
  TOP_UP_FILE_UPLOAD_ALLOW_DUPLICATES: false,
  SUPPORT_EMAIL: process.env.REACT_APP_SUPPORT_EMAIL,
  SUPPORT_PHONE_NO: process.env.REACT_APP_SUPPORT_PHONE_NO,
});

export default EnvConstants;
