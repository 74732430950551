import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";
import mockData from "../../mock/topup-client-charts-api-mockup.json";

const useGetTotalTransactionOperatorWiseStats = () => {
  const { makeApiCall } = useApi();

  const getTotalTransactionOperatorWiseStats = async (noOfDays = 7) => {
    const url = `${ApiConstants.BASE_URL}/api/reports/total-transaction-operator-wise?days=${noOfDays}`;
    try {
      const response = await makeApiCall({
        url,
      });
      const { data } = response?.data;

      return data;
    } catch (error) {
      // console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getTotalTransactionOperatorWiseStats1 = async (noOfDays = 7) => {
    const { TOTAL_TRANSACTION_OPERATOR_WISE, MOCK_FETCH_DELAY_IN_MILLIS } =
      mockData;
    const { response } = TOTAL_TRANSACTION_OPERATOR_WISE;
    const { totalSent, dataSet } = response;

    // Wait for 3 seconds
    await new Promise((resolve) =>
      setTimeout(resolve, MOCK_FETCH_DELAY_IN_MILLIS)
    );

    return {
      totalSent,
      dataSet: dataSet.slice(0, noOfDays),
    };
  };
  return getTotalTransactionOperatorWiseStats;
};

export default useGetTotalTransactionOperatorWiseStats;
