import { useUser } from "../../features/UserSlice";
import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";

const useGetNotificationSettings = () => {
  const { updateNotifyLowBalanceByEmail, updateBalanceThreshold } = useUser();

  const { makeApiCall, loading } = useApi();
  const url =
    ApiConstants.BASE_URL + ApiConstants.ENDPOINT_GET_NOTIFICATION_SETTINGS;

  const getNotificationSettings = async () => {
    try {
      const response = await makeApiCall({
        url,
      });
      const { data } = response?.data;
      updateNotifyLowBalanceByEmail(data?.notifyOnBalanceLow);
      updateBalanceThreshold(data?.balanceThreshold);
    } catch (error) {
      // console.log(error);
    }
  };
  return { getNotificationSettings, loading };
};

export default useGetNotificationSettings;
