import { configureStore, combineReducers } from "@reduxjs/toolkit";
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // localStorage
// import storageSession from "redux-persist/lib/storage/session"; // sessionStorage
import authReducer from "./features/AuthSlice";
import userReducer from "./features/UserSlice";
import statsReducer from "./features/StatsSlice";

// Memory storage (in-memory, non-persistent)
const createNoopStorage = () => {
  return {
    getItem() {
      return Promise.resolve(null);
    },
    setItem() {
      return Promise.resolve();
    },
    removeItem() {
      return Promise.resolve();
    },
  };
};
const memoryStorage = createNoopStorage();

// Persist configurations for local storage slices
const localPersistConfig = {
  key: "local",
  version: 1,
  storage, // Use localStorage
};

// Persist configurations for session storage slices
/*const sessionPersistConfig = {
  key: "session",
  version: 1,
  storage: storageSession, // Use sessionStorage
};*/

// Persist configurations for in-memory slices
const memoryPersistConfig = {
  key: "memory",
  version: 1,
  storage: memoryStorage, // In-memory (non-persistent)
};

// Combine local storage reducers
const localReducer = combineReducers({
  auth: authReducer,
  user: userReducer,
});

// Combine session storage reducers
// const sessionReducer = combineReducers({});

// Combine in-memory reducers
const memoryReducer = combineReducers({
  stats: statsReducer,
});

// Persist reducers for each type
const persistedLocalReducer = persistReducer(localPersistConfig, localReducer);
// const persistedSessionReducer = persistReducer( sessionPersistConfig, sessionReducer);
const persistedMemoryReducer = persistReducer(
  memoryPersistConfig,
  memoryReducer
);

// Combine all persisted reducers
const rootReducer = combineReducers({
  local: persistedLocalReducer,
  // session: persistedSessionReducer,
  memory: persistedMemoryReducer,
});

// Configure the store
export const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: {
        // Ignore these action types
        ignoredActions: ["persist/PERSIST", "persist/REHYDRATE"],
        // Ignore paths for checking serializability
        ignoredPaths: ["register", "rehydrate"],
      },
    }),
});

// Create persistor for redux-persist
export const persistor = persistStore(store);
