import React from "react";

const InputNumber = ({ name, value, onChange, ...props }) => {
  const handleChange = (event) => {
    event.preventDefault();
    const { value } = event.target;
    const isNumber = !isNaN(value);
    // console.log(`isNumber: ${isNumber}\tvalue : ${value}`);
    if (isNumber && typeof onChange === "function") {
      // console.log("inside");
      onChange(event);
    }
  };
  return (
    <input
      className="input-number w-full"
      type="text"
      name={name}
      value={value}
      onChange={handleChange}
      {...props}
    />
  );
};

export default InputNumber;
