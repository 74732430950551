import { useAuth } from "../../features/AuthSlice";
import axios from "axios";
import ApiConstants from "../../constants/ApiConstants";

const useGetNetworkRules = () => {
  const { setNetworkRules } = useAuth();
  const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_GET_CREDIT_LIMITS;

  const getNetworkRules = async () => {
    try {
      const response = await axios.request({
        method: "GET",
        url: url,
      });
      const { data } = response?.data;
      setNetworkRules(data);
    } catch (error) {
      // console.log(error);
    }
  };
  return getNetworkRules;
};

export default useGetNetworkRules;
