import React from "react";
import CanvasJSReact from "@canvasjs/react-charts";
const CanvasJSChart = CanvasJSReact.CanvasJSChart;

const SplineChart = ({ data = [] }) => {
  const weekday = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  // Function to generate data points for a given key
  function generateDataPoints(key) {
    if (!data || !Array.isArray(data) || data.length === 0) {
      return [];
    }
    if (data.length <= 7) {
      return data.map((entry) => ({
        y: entry[key],
        label: weekday[new Date(entry.date).getDay()].slice(0, 3),
      }));
    }

    if (data.length <= 30) {
      return data.map((entry) => ({
        y: entry[key],
        label: formatDate(entry.date),
      }));
    }

    return data.map((entry) => ({
      y: entry[key],
      label: formatDate(entry.date, true),
    }));
  }

  const options = {
    animationEnabled: true,
    backgroundColor: "transparent",
    axisX: {
      labelFontColor: "#b3b8cd",
      tickLength: 0,
      gridThickness: 1,
      lineThickness: 0,
      interval: data.length <= 7 ? 1 : data.length <= 30 ? 2 : 15,  
    },
    axisY: {
      labelFontColor: "#b3b8cd",
      gridColor: "#2e3b4e",
      lineThickness: 0,
      tickLength: 0,
      gridThickness: 1,
      minimum: 0,
    },
    data: [
      {
        type: "splineArea",
        color: "rgba(52, 209, 191, 0.2)",
        lineColor: "#34d1bf",
        lineThickness: 2,
        markerSize: 0,
        dataPoints: generateDataPoints("transactionCount"),
      },
    ],
  };

  return <CanvasJSChart options={options} />;
};

export default SplineChart;

function formatDate(dateString, showMonth = false) {
  const date = new Date(dateString); // Create a Date object from the input string
  const day = String(date.getDate()).padStart(2, "0"); // Get day and pad to 2 digits
  if (!showMonth) {
    return `${day}`;
  }
  const month = String(date.getMonth() + 1).padStart(2, "0"); // Get month (note: months are 0-based)
  return `${day}/${month}`; // Return the formatted date
}
