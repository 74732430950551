import axios from "axios";
import { useAuth } from "../../features/AuthSlice";
import ApiConstants from "../../constants/ApiConstants";
import useApp from "../../context/AppState";
import SessionExpiredPopup from "../../components/SessionExpiredPopup";
import AccountBlockedPopup from "../../components/AccountBlockedPopup";

const useRefreshToken = () => {
  const { token, saveToken } = useAuth();
  const { showPopup } = useApp();

  const refreshToken = async () => {
    const url = `${ApiConstants.BASE_URL}${ApiConstants.ENDPOINT_REFRESH_TOKEN}`;
    if (Date.now() > token.refreshTokenExpiry) {
      showPopup(<SessionExpiredPopup />);
      return token.token;
    } else {
      try {
        const response = await axios.request({
          url,
          headers: { Authorization: `Bearer ${token?.refreshToken}` },
        });
        const { data } = response?.data;
        const tokenObj = {
          token: data?.token,
          expiresAt: data?.tokenExpiry,
          refreshToken: data?.refreshToken,
          refreshTokenExpiry: data?.refreshTokenExpiry,
        };
        saveToken(tokenObj);
        return data.token;
      } catch (error) {
        if (
          error.response &&
          (error.response.status === 401 || error.response.status === 403)
        ) {
          showPopup(<AccountBlockedPopup />);
        }
        // console.log(error);
      }
    }
  };
  return refreshToken;
};

export default useRefreshToken;
