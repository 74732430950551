import React, { useEffect, useState } from "react";
import fallbackSrc from "../assets/images/person.png";

const ImageFetcher = ({ src, className }) => {
  const [imgSrc, setImgSrc] = useState(fallbackSrc);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const cachedImage = sessionStorage.getItem(src); // Check if image is cached

        if (cachedImage) {
          setImgSrc(cachedImage); // Use cached image
          return;
        }

        const response = await fetch(src);

        if (!response.ok) {
          throw new Error("Image failed to load");
        }

        // Convert the response to a Blob
        const imageBlob = await response.blob();
        const imageObjectURL = URL.createObjectURL(imageBlob);

        // Cache the image in sessionStorage
        sessionStorage.setItem(src, imageObjectURL);

        setImgSrc(imageObjectURL);
      } catch (error) {
        console.error("Error fetching image:", error);
        setImgSrc(fallbackSrc); // Fallback image if the fetch fails
      }
    };

    fetchImage();
  }, [src]);

  return <img src={imgSrc} alt="img" className={className} />;
};

export default ImageFetcher;
