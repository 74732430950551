import { useEffect, useMemo, useState } from "react";
import useApi from "../../useApi";
import ApiConstants from "../../../constants/ApiConstants";
import FileStatuses, {
  findFileStatus,
} from "../../../constants/File/FileStatus";

const useGetFiles = () => {
  const [loadedOnce, setLoadedOnce] = useState(false);
  const [files, setFiles] = useState([]);

  const formattedFiles = useMemo(
    () =>
      files
        .filter((entry) => entry.fileStatusCode !== FileStatuses.DELETED.value)
        .map((file) => {
          const status = findFileStatus(file.fileStatusCode);
          return {
            ...file,
            className: status.className,
            status: status.text,
            isClickable: status?.isClickable,
            isProcessing: status?.isProcessing,
          };
        }),
    [files]
  );

  useEffect(() => {
    const hasProcessing = files.some(
      (file) =>
        file.fileStatusCode === FileStatuses.UPLOADED.value ||
        file.fileStatusCode === FileStatuses.PREPROCESSING.value
    );

    if (hasProcessing) {
      const interval = setInterval(() => {
        getFiles();
      }, 3000);

      return () => clearInterval(interval);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [files]);

  const { makeApiCall, loading } = useApi();
  const url = ApiConstants.BASE_URL + ApiConstants.ENDPOINT_GET_FILES;

  useEffect(() => {
    getFiles();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const getFiles = async () => {
    try {
      const response = await makeApiCall({
        url,
        params: {
          page: 0,
          size: 100,
        },
      });
      const { data } = response?.data;
      setFiles(data);
    } catch (error) {
      // console.log(error);
    } finally {
      if (!loadedOnce) {
        setLoadedOnce(true);
      }
    }
  };

  return {
    files,
    getFiles,
    formattedFiles,
    fetchingFirst: loading && !loadedOnce,
    loading,
    loadedOnce,
  };
};

export default useGetFiles;
