import ApiConstants from "../../constants/ApiConstants";
import useApi from "../useApi";
import mockData from "../../mock/topup-client-charts-api-mockup.json";

const useGetTotalTransactionStats = () => {
  const { makeApiCall } = useApi();

  const getTotalTransactionStats = async (noOfDays = 7) => {
    const url = `${ApiConstants.BASE_URL}/api/reports/total-transaction?days=${noOfDays}`;
    try {
      const response = await makeApiCall({
        url,
      });
      const { data } = response?.data;

      return data;
    } catch (error) {
      // console.log(error);
    }
  };

  // eslint-disable-next-line no-unused-vars
  const getTotalTransactionStats1 = async (noOfDays = 7) => {
    const { TOTAL_TRANSACTION, MOCK_FETCH_DELAY_IN_MILLIS } = mockData;
    const { response } = TOTAL_TRANSACTION;
    const { totalSent, totalReceived, dataSet } = response;

    // Wait for 3 seconds
    await new Promise((resolve) =>
      setTimeout(resolve, MOCK_FETCH_DELAY_IN_MILLIS)
    );

    return {
      totalSent,
      totalReceived,
      dataSet: dataSet.slice(0, noOfDays),
    };
  };

  return getTotalTransactionStats;
};

export default useGetTotalTransactionStats;
